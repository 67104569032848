<main class="main">
    <section class="main-content">
        <div class="header">
            <div class="header-content">
                <div class="header-contentLeft">
                    <p class="header-title">Seb Ferrer</p>
                </div>
                <div class="header-contentRight">
                    <a class="github-logo" href="https://github.com/sebferrer" [matTooltip]="'My GitHub'">
                        <img _ngcontent-nbb-c124="" src="/assets/svg/github.svg" height="28" alt="logo">
                    </a>
                </div>
            </div>
        </div>
        <div class="container">
            <router-outlet></router-outlet>
        </div>
    </section>
</main>