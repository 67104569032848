<div class="projects-grid">
    <div *ngFor="let project of projects" class="card tile">
        <div class="card-content" (click)="openProject(project.id)">
            <div class="card-content-overlay"></div>
            <div class="card-content-picture">
                <div class="card-content-text">
                    <p>{{ project.name }}</p>
                </div>
                <img [src]="project.picture" class="tile-picture" />
            </div>
        </div>
        <footer class="card-footer">
            <div class="card-footer-center">
                <!--span *ngFor="let techno of project.technos" class="techno-logo-container"
                    [luTooltip]="getTechno(techno).name" [luTooltipPosition]="'below'" [luTooltipEnterDelay]="100" [luTooltipLeaveDelay]="0">
                    <img [src]="getTechno(techno).picture" class="techno-logo" />
                </span-->
                <span *ngFor="let techno of project.technos" class="techno-logo-container"
                    [matTooltip]="getTechno(techno).name" #tooltip="matTooltip" (click)="tooltip.toggle()">
                    <img [src]="getTechno(techno).picture" class="techno-logo" />
                </span>
            </div>
        </footer>
    </div>
</div>